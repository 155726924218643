export { getPageTitleAndDesc }

function getPageTitleAndDesc(pageContext: {
  exports: { documentProps?: { title: string; description: string; Keywords: string } }
  documentProps?: { title: string; description: string; Keywords: string }
}) {
  const title =
    // For static titles (defined in the `export { documentProps }` of the page's `.page.js`)
    (pageContext.exports.documentProps || {}).title ||
    // For dynamic tiles (defined in the `export addContextProps()` of the page's `.page.server.js`)
    (pageContext.documentProps || {}).title ||
    '404'
  const desc =
    // For static titles (defined in the `export { documentProps }` of the page's `.page.js`)
    (pageContext.exports.documentProps || {}).description ||
    // For dynamic tiles (defined in the `export addContextProps()` of the page's `.page.server.js`)
    (pageContext.documentProps || {}).description ||
    'Demo_desc'
  const Keywords = (pageContext.exports.documentProps || {}).Keywords ||
    (pageContext.documentProps || {}).Keywords ||
    'Demo_Keywords'
  return {
    title,
    desc,
    Keywords
  }
}
