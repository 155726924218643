<template>
  <div class="overlay" v-if="appStore.bulletin3.length > 0">
    <div class="box">
      <img class="hidden-xs-only" :src="$imgs['notice/notice_top.png']" alt="" />
      <img class="hidden-sm-and-up" :src="$imgs['notice/notice_top_mobile.png']" alt="" />
      <p class="bt">{{ appStore.bulletin3[0].bulletinName }}</p>
      <el-carousel trigger="click" arrow="never" :indicator-position="appStore.bulletin3.length > 1 ? 'outside' : 'none'" :autoplay="false">
        <el-carousel-item v-for="item in appStore.bulletin3" :key="item">
          <div class="cont">
            <span>{{ item.context }} </span>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
  <div class="overlay" v-else-if="appStore.bulletin2.length > 0">
    <div class="box">
      <img class="hidden-xs-only" :src="$imgs['notice/notice_top.png']" alt="" />
      <img class="hidden-sm-and-up" :src="$imgs['notice/notice_top_mobile.png']" alt="" />
      <p class="bt">{{ appStore.bulletin2[0].bulletinName }}</p>
      <el-carousel trigger="click" arrow="never" :indicator-position="appStore.bulletin3.length > 1 ? 'outside' : 'none'" :autoplay="false">
        <el-carousel-item v-for="item in appStore.bulletin2" :key="item">
          <div class="cont">
            <span>{{ item.context }} </span>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="close" @click="appStore.bulletin2 = []">{{ $t('我知道了') }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useAppStore } from '../store'
const appStore = useAppStore()

// body禁止滚动
// onMounted(() => {
//   if (appStore.bulletin2 || appStore.bulletin3) document.body.style.overflow = 'hidden'
// })
</script>
<style scoped lang="less">
.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  color: #fff;
  font-size: 20px;
  ::v-deep(.el-carousel--horizontal) {
    padding-bottom: 30px;
  }
  .box {
    width: calc(1000 / 1920 * 100%);
    height: auto;
    border-radius: 16px 16px 16px 16px;
    background: #ffffff;
    img {
      width: 100%;
      display: block;
    }
    .bt {
      font-size: 1.67vw;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      padding-bottom: 18px;
      padding: 40px 48px 18px;
    }
    .cont {
      width: calc(100% - 96px);
      height: 100%;
      margin: 0 auto;
      overflow: auto;
      line-height: 1.5vw;
      span {
        color: rgba(0, 0, 0, 0.8);
        font-size: 0.84vw;
        font-weight: 400;
      }
    }
    .close {
      width: calc(600 / 1920 * 100%);
      height: 3vw;
      line-height: 3vw;
      background: linear-gradient(123deg, #00a7ff 0%, #00ceff 100%);
      border-radius: 30px;
      font-size: 1.042vw;
      font-weight: 600;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
      margin: 0 auto 64px;
    }
  }
  /* 修改滚动条宽度 */
  ::-webkit-scrollbar {
    width: 4px;
  }
  /* 修改滚动条手柄颜色 */
  ::-webkit-scrollbar-thumb {
    background: #d0efff;
  }
}
@media screen and (max-width: 768px) {
  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    color: #fff;
    font-size: 20px;
    .box {
      width: 311px;
      height: auto;
      border-radius: 16px 16px 16px 16px;
      background: #ffffff;
      img {
        width: 138px;
        margin: 16px auto;
        display: block;
      }
      .bt {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        padding: 0;
        padding-bottom: 8px;
      }
      ::v-deep(.el-carousel__container) {
        height: 167px;
      }
      .cont {
        width: 263px;
        height: 100%;
        overflow: auto;
        span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(33, 33, 33, 0.8);
          line-height: 22px;
        }
      }
      .close {
        width: 263px;
        height: 40px;
        line-height: 40px;
        background: linear-gradient(123deg, #00a7ff 0%, #00ceff 100%);
        border-radius: 24px 24px 24px 24px;
        font-size: 16px;
        font-family: PingFang HK, PingFang HK;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin: 0 auto 24px;
      }
    }
  }
}
</style>
