<template>
  <div class="flex">
    <el-backtop :bottom="100" class="hidden-xs-only">
      <div class="box">
        <el-icon><ArrowUpBold /></el-icon>
        <span>TOP</span>
      </div>
    </el-backtop>
    <div class="el-backtop chatBox">
      <div class="chat" @click="getLiveChatUrl">
        <img :src="t('Footer.chat')" :alt="$t('Footer.即時chat')"/>
      </div>
    </div>
    <div class="el-backtop language hidden-sm-and-up" v-if="isClient">
      <el-dropdown trigger="click">
        <div class="chat" @click="visible = true">{{ locale === 'sc' ? '简' : locale === 'en' ? 'EN' : '繁' }}</div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="changeLang('tc')">{{ $t('Header.繁') }}</el-dropdown-item>
            <el-dropdown-item @click="changeLang('sc')">{{ $t('Header.简') }}</el-dropdown-item>
            <el-dropdown-item @click="changeLang('en')">EN</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <!-- <el-popover placement="top" :visible="visible" width="100px">
        <template #reference>
          <div class="chat" @click="visible = true">{{ locale === 'sc' ? '简' : locale === 'en' ? 'EN' : '繁' }}</div>
        </template>
        <div class="language">
          <span @click="changeLang('tc')">{{ $t('Header.繁') }}</span>
          <span @click="changeLang('sc')">{{ $t('Header.简') }}</span>
          <span @click="changeLang('en')">EN</span>
        </div>
      </el-popover> -->
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { ArrowUpBold } from '@element-plus/icons-vue'
import 'element-plus/theme-chalk/display.css'
import { useI18n } from 'vue-i18n'
import { useApi } from '../hook'
import { usePageContext } from '../renderer/usePageContext'
// 更改语言
const { locale, t } = useI18n()
const visible = ref(false)

const isClient = ref(false)
onMounted(() => {
  isClient.value = true
  console.log(window.location.href)
  const url = window.location.href
  if (url.includes('livechat=true')) {
    getLiveChatUrl()
  }
})
//  获取live-chat的跳转地址
async function getLiveChatUrl() {
  const env = import.meta.env
  const lang = locale.value === 'tc' ? 'T' : locale.value === 'sc' ? 'S' : 'E'
  await useApi()
    .get(`${env.VITE_APP_AXIOS_OPEN_STAGE_URL}/live-chat?lang=${lang}`)
    .then((res) => {
      window.location.href = res
    })
}

// locale.value = usePageContext().locale
// watch(usePageContext(), (context) => {
//   locale.value = context.locale
// })
const changeLang = (lang) => {
  visible.value = false
  const link = location.href.replace(/(\/tc|\/sc|\/en|\/$)/, '/' + lang)
  location.href = link
}
</script>
<style scoped lang="less">
.flex {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
      font-size: 16px;
    }
    span {
      font-size: 12px;
    }
  }
  ::v-deep(.el-backtop) {
    width: 64px;
    height: 64px;
  }
  .chatBox {
    display: block;
    bottom: 180px;
    right: 40px;
    .chat {
      width: 64px;
      height: 64px;
      z-index: 999;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .language {
    display: block;
    bottom: 40px;
    right: 15px;
    .chat {
      width: 64px;
      height: 64px;
      text-align: center;
      line-height: 64px;
      z-index: 999;
    }
  }
}
@media screen and (max-width: 768px) {
  .flex {
    .chatBox {
      bottom: 120px;
      right: 15px;
    }
  }
  .language {
    span {
      text-align: center;
      display: block;
      padding: 10px 0;
    }
  }
}
</style>
<style lang="less">
.el-popover.el-popper {
  min-width: auto !important;
}
</style>
