<template>
  <el-footer class="web_footer hidden-xs-only">
    <div class="item left">
      <div class="logo"><img :src="$imgs['footer/slash_logo@2x.png']" alt="" /></div>
      <div class="link">
        <span @click="goRouter('/slashintro')">{{ $t('Footer.认识s/ash') }}</span>
        <span @click="goRouter('/media_coverage')">{{ $t('Footer.媒体报导') }}</span>
        <span @click="goRouter('/order_enquiry')">{{ $t('Footer.订单查询') }}</span>
        <span @click="goRouter('/helpcentre')">{{ $t('Footer.帮助中心') }}</span>
        <span v-if="isShowBusiness" @click="goRouter('/business_collaboration')">{{ $t('Footer.商业合作') }}</span>
        <span @click="goRouter('/TNC')">{{ $t('Footer.条款及细则') }}</span>
      </div>
      <div class="copyright">{{ $t('Footer.版权') }}</div>
    </div>
    <div class="item right">
      <div class="medium" @click="goUrl('https://www.instagram.com/slashsim.hk/')">
        <img :src="$imgs['footer/ins@2x.png']" alt="" />
        <span>slashsim.hk</span>
      </div>
      <div class="medium" @click="goUrl('https://wa.me/66761200')">
        <img :src="$imgs['footer/whatsapp@2x.png']" alt="" />
        <span>6676-1200 (Whatsapp)</span>
      </div>
    </div>
  </el-footer>
  <el-footer class="mobile_footer hidden-sm-and-up" :class="{ black: isBlack }">
    <div class="menu">
      <div class="item">
        <span @click="goRouter('/slashintro')" class="underline">{{ $t('Footer.认识s/ash') }}</span>
      </div>
      <div class="item">
        <span @click="goRouter('/media_coverage')" class="underline">{{ $t('Footer.媒体报导') }}</span>
      </div>
      <div class="item">
        <span class="underline hidden-xs-only" @click="goRouter('/order_enquiry')">{{ $t('Footer.订单查询') }}</span>
        <span class="underline hidden-sm-and-up" @click="goUrl(`${env.VITE_APP_SLASH_OPEN}/searchOrder?lang=${locale}`)">{{
          $t('Footer.订单查询')
        }}</span>
      </div>
      <div class="item">
        <span @click="goRouter('/helpcentre')" class="underline">{{ $t('Footer.帮助中心') }}</span>
      </div>
      <div class="item" v-if="isShowBusiness">
        <span @click="goRouter('/business_collaboration')" class="underline">{{ $t('Footer.商业合作') }}</span>
      </div>
      <div class="item">
        <span @click="goRouter('/TNC')" class="underline">{{ $t('Footer.条款及细则') }}</span>
      </div>
      <div :class="{ activity: contactIsOpen, item: true }">
        <div class="title" @click="contactIsOpen = !contactIsOpen">
          <span class="underline">{{ $t('Footer.联络我们') }}</span>
          <el-icon class="arrowRight"><ArrowRight /></el-icon>
        </div>
        <div class="contact" v-show="contactIsOpen">
          <div class="app" @click="goUrl('https://www.instagram.com/slashsim.hk/')">
            <img :src="$imgs['footer/ins@2x.png']" alt="" />
            <i>slashsim.hk</i>
          </div>
          <div class="app" @click="goUrl('https://wa.me/66761200')">
            <img :src="$imgs['footer/whatsapp@2x.png']" alt="" />
            <i>6676-1200 (Whatsapp)</i>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">{{ $t('Footer.版权') }}</div>
  </el-footer>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import 'element-plus/theme-chalk/display.css'
import { useI18n } from 'vue-i18n'
import { ArrowRight, Service } from '@element-plus/icons-vue'
import { navigate } from 'vite-plugin-ssr/client/router'
import { useAppStore } from '../store'
import { usePageContext } from '../renderer/usePageContext'

const env = import.meta.env
const appStore = useAppStore()
const contactIsOpen = ref(false)
const { locale } = useI18n()
function goRouter(name: string) {
  navigate('/' + locale.value + name)
}
const isBlack = ref(false)
function goUrl(url: string) {
  window.location.href = url
}

watch(
  usePageContext(),
  (context) => {
    const href = context.urlPathname
    if (href.indexOf('joox_jp') !== -1) {
      isBlack.value = true
    } else {
      isBlack.value = false
    }
  },
  { immediate: true }
)

// 判断商务合作是否在活动中
const isShowBusiness = ref(false)
watch(
  () => appStore.activityStatus,
  () => {
    const state = appStore.activityStatus.filter((item) => item.activityCode === '7e556e9f01f2')
    if (state[0].activityState === 0) {
      isShowBusiness.value = true
    }
  }
)
</script>
<style lang="less"></style>
<style scoped lang="less">
.web_footer {
  overflow: hidden;
  height: 367px;
  background: url('@/assets/imgs/footer/bg@2x.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  .item {
    padding: 0 10%;
    &.left {
      margin-top: 56px;
      .logo {
        width: 118px;
        height: auto;
        img {
          width: 100%;
        }
      }
      .link {
        display: flex;
        flex-wrap: wrap;
        span {
          white-space: nowrap;
          display: block;
          width: 33%;
          font-size: 18px;
          cursor: pointer;
          color: #ffffff;
          text-decoration: underline;
          text-underline-offset: 3px;
          padding-bottom: 24px;
        }
      }
      .copyright {
        margin-top: 76px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }
    &.right {
      margin-top: 160px;
      > .medium {
        display: flex;
        align-items: center;
        height: 40px;
        width: auto;
        margin-bottom: 20px;
        cursor: pointer;
        img {
          height: 100%;
          margin-right: 10px;
        }
        span {
          font-size: 18px;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1400px) {
  .web_footer .item {
    padding: 0 20px;
  }
}
@media screen and (max-width: 768px) {
  .mobile_footer {
    height: auto;
    background: url('@/assets/imgs/footer/mobile_bg.png') no-repeat center top;
    background-size: cover;
    padding: 120px 0 90px;
    &.black {
      background: #000 url('@/assets/imgs/footer/mobile_bg.png') no-repeat center top;
      background-size: cover;
    }
    .item {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      padding: 16px;
      .underline {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
      &.activity {
        background: rgba(0, 167, 255, 0.6);
        margin-bottom: 20px;
        .title {
          .arrowRight {
            transform: rotate(90deg);
          }
        }
        .contact {
          div.app {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            img {
              width: 30px;
              margin-right: 10px;
            }
            > i {
              display: block;
              font-style: normal;
              font-size: 18px;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;
        .arrowRight {
          transition: all 0.3s;
          transform: rotate(0);
          font-size: 20px;
          color: #fff;
        }
      }
    }
    .copyright {
      padding: 0 16px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
