<template>
  <el-container class="layout-wrap">
    <Header></Header>
    <el-main class="content">
      <slot />
    </el-main>
    <Footer></Footer>
    <Backtop></Backtop>
    <Notice></Notice>
    <!-- <el-footer></el-footer> -->
    <!-- <button @click="changeLang('tc')">繁体</button>
    <button @click="changeLang('sc')">简体</button>
    <button @click="changeLang('en')">英文</button> -->
  </el-container>
</template>

<script lang="ts" setup>
import Header from '../layout/header.vue'
import Footer from '../layout/footer.vue'
import Backtop from '../layout/backtop.vue'
import Notice from '../layout/notice.vue'
import { usePageContext } from './usePageContext'
import { useI18n } from 'vue-i18n'
import { watch, onMounted } from 'vue'
import { useAppStore } from '../store'

const appStore = useAppStore()
const { locale } = useI18n()

onMounted(async () => {
  await appStore.getActivityState()
  await appStore.getSlash100GBState()
  // await appStore.getChannelState('JOOX')
})

let routerName = ''
watch(
  usePageContext(),
  async (context) => {
    if (context.locale) {
      console.log(context.urlPathname)
      locale.value = context.locale
      if (routerName != context.urlPathname) {
        routerName = context.urlPathname
        let name = context.urlPathname.endsWith('/') ? context.urlPathname.slice(0, -1) : context.urlPathname
        let route = name.split('/').pop() as string
        if (context.urlPathname === `/${locale.value}/` || context.urlPathname === '/') {
          route = 'home'
        }
        let lang = locale.value === 'tc' ? 'zh_TW' : locale.value === 'sc' ? 'zh_CN' : 'en_US'
        console.log(context.urlPathname)
        await appStore.queryBulletin(lang, route)
      }
    }
  },
  {
    immediate: true
  }
)
// const changeLang = (lang: 'tc' | 'sc' | 'en') => {
//   locale.value = lang
// }
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  font-family: 'Source Han Sans CN', 'Microsoft YaHei', sans-serif;
}

html {
  font-size: 72.5391px;
}
img {
  display: block;
}
i,
em,
b {
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
}

.layout-wrap {
  overflow: hidden;
  min-height: 100vh;
  flex-direction: column !important;
  .el-main {
    --el-main-padding: 0;
    overflow: hidden;
  }
}
// 录播图指示器
.el-carousel__indicator {
  button {
    background-color: rgba(0, 167, 255, 0.2);
  }
  &.is-active {
    button {
      background-color: rgba(0, 167, 255, 1);
    }
  }
}
</style>
