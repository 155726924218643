import { createSSRApp, defineComponent, h, reactive, markRaw } from 'vue'
import PageShell from './PageShell.vue'
import { setPageContext } from './usePageContext'
import pinia from '../store'
import { i18n } from '../i18n'
import * as imgs from '../assets/imgs'
// import ArcoVue from '@arco-design/web-vue'
// import '@arco-design/web-vue/dist/arco.css'
import ElementPlus from 'element-plus'
import Vue3Marquee from 'vue3-marquee'
import 'element-plus/dist/index.css'
import { ID_INJECTION_KEY } from 'element-plus'
import type { Component, PageContext, PageProps } from './types'

export { createApp }

function createApp(pageContext: PageContext) {
  const { Page } = pageContext
  const PageWithLayout = defineComponent({
    setup() {
      const page = markRaw(Page)
      const pageProps = markRaw(pageContext.pageProps || {})
      return {
        page,
        pageProps
      }
    },
    render() {
      return h(
        PageShell,
        {},
        {
          default: () => {
            return h(pageContextReactive.Page, pageContextReactive.pageProps || {})
          }
        }
      )
    }
  })
  const app = createSSRApp(PageWithLayout)
  app.provide(ID_INJECTION_KEY, {
    prefix: 1024,
    current: 0
  })
  app.use(pinia).use(i18n).use(imgs).use(ElementPlus).use(Vue3Marquee)

  // We use `app.changePage()` to do Client Routing, see `_default.page.client.js`
  objectAssign(app, {
    changePage: (pageContext: PageContext) => {
      Object.assign(pageContextReactive, pageContext)
      PageWithLayout.page = markRaw(pageContext.Page)
      PageWithLayout.pageProps = markRaw(pageContext.pageProps || {})
    }
  })

  const pageContextReactive = reactive(pageContext)

  // Make pageContext available from any Vue component
  setPageContext(app, pageContextReactive)

  return {
    app,
    pinia
  }
}

// Same as `Object.assign()` but with type inference
function objectAssign<Obj extends object, ObjAddendum>(obj: Obj, objAddendum: ObjAddendum): asserts obj is Obj & ObjAddendum {
  Object.assign(obj, objAddendum)
}
