export { render }
export const clientRouting = true
export const prefetchStaticAssets = 'viewport'

import { navigate } from "vite-plugin-ssr/client/router"
import { useAppStore } from "../store"
import { createApp } from './app'
import { getPageTitleAndDesc } from './getPageTitleAndDesc'
import type { PageContext } from './types'
import type {
  //*
  // When using Client Routing https://vite-plugin-ssr.com/clientRouting
  PageContextBuiltInClientWithClientRouting as PageContextBuiltInClient
  /*/
  // When using Server Routing
  PageContextBuiltInClientWithServerRouting as PageContextBuiltInClient
  //*/
} from 'vite-plugin-ssr/types'

let app: ReturnType<typeof createApp>['app']
async function render(pageContext: PageContextBuiltInClient & PageContext) {
  pageContext.exports.errorPageChangeLang && pageContext.exports.errorPageChangeLang(pageContext)
  if (!app) {
    const instace = createApp(pageContext)
    app = instace.app
    instace.pinia.state.value = pageContext.initialStoreState
    app.mount('#app')
  } else {
    app.changePage(pageContext)
  }
  document.title = getPageTitleAndDesc(pageContext).title
  if (document.querySelector("meta[name='description']")) {
    (document.querySelector("meta[name='description']") as Element).setAttribute("content", getPageTitleAndDesc(pageContext).desc)
  }
  const { redirectTo, urlPathname } = pageContext
  // console.log(pageContext)
  // if (!useAppStore().isLogin && urlPathname != '/login') {
  //   navigate(redirectTo)
  //   return
  // }
}