<template>
  <div class="marquee-box" v-show="appStore.bulletin1.length > 0 && showTips && isClient">
    <div class="bg">
      <div class="icon">
        <img :src="$imgs['header/tips.png']" />
      </div>
      <Vue3Marquee>
        <div class="marquee-cont">
          <span>
            <i v-for="(item, index) in appStore.bulletin1" :key="index">
              {{ item.context }}
            </i>
          </span>
        </div>
      </Vue3Marquee>
      <div class="close" @click="showTips = !showTips">
        <img :src="$imgs['header/close.png']" />
      </div>
    </div>
  </div>
  <el-header class="header">
    <div class="content hidden-xs-only">
      <div class="logo">
        <img @click="goHome" :src="$imgs['header/slash_logo.png']" :alt="$t('Header.s/ash，以CMHK中國移動')" />
      </div>
      <el-menu class="menu" :default-active="activeIndex" background-color="#f5fbff" mode="horizontal" :ellipsis="false">
        <el-menu-item index="1" v-if="isClient" @click="goRouter('/')">{{ $t('Header.首页') }}</el-menu-item>
        <!-- <el-sub-menu index="1" v-if="isClient">
          <template #title>{{ $t('Header.5G月费计划') }}</template>
          <el-menu-item index="1-1" @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/slash`)">{{ $t('Header.5G月费计划') }}</el-menu-item>
        </el-sub-menu> -->
        <el-sub-menu index="2" v-if="isClient">
          <template #title>{{ $t('Header.认识s/ash') }}</template>
          <el-menu-item index="2-1" @click="goRouter('/slashintro')">{{ $t('Header.咩系s/ash') }}</el-menu-item>
          <el-menu-item index="2-2">
            <a class="submenu" :href="'/' + locale + '/slashintro#brand'"> {{ $t('Header.品牌介绍') }}</a>
          </el-menu-item>
          <el-menu-item index="2-3">
            <a class="submenu" :href="'/' + locale + '/slashintro#media_coverage'"> {{ $t('Header.媒体报导') }}</a>
          </el-menu-item>
          <el-menu-item index="2-4">
            <a class="submenu" :href="'/' + locale + '/slashintro#network'"> {{ $t('Header.网络实测') }}</a>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3" v-if="isClient">
          <template #title>{{ $t('Header.最新着数') }}</template>
          <el-menu-item index="3-1" v-if="isShowDashi" @click="goRouter('/ambassador_recruitment')">{{
            $t('Header.s/asher大使')
          }}</el-menu-item>
          <el-menu-item index="3-2" v-if="gameActivity" @click="goRouter('/Gaming_Essentials')">{{
            $t('Header.手游必備礼遇')
          }}</el-menu-item>
          <el-menu-item index="3-3" v-if="appStore.slash100GBState === 0" @click="goRouter('/100GB_offer')">{{
            $t('Header.免费拎100GB')
          }}</el-menu-item>
          <!-- <el-menu-item index="3-4" v-if="appStore.JooxState === 0" @click="goRouter('/joox_jp')">{{ $t('Header.joox_jp') }}</el-menu-item> -->
        </el-sub-menu>
        <el-sub-menu index="4" v-if="isClient">
          <template #title>{{ $t('Header.上台Tips') }}</template>
          <el-menu-item index="4-1" @click="goRouter('/porting_guide')">{{ $t('Header.转台教学') }}</el-menu-item>
          <el-menu-item index="4-2" @click="goRouter('/helpcentre')">{{ $t('Header.帮助中心') }}</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="5" v-if="isClient">
          <template #title>{{ locale === 'sc' ? '简' : locale === 'en' ? 'EN' : '繁' }}</template>
          <el-menu-item @click="changeLang('tc')">{{ $t('Header.繁') }}</el-menu-item>
          <el-menu-item @click="changeLang('sc')">{{ $t('Header.简') }}</el-menu-item>
          <el-menu-item @click="changeLang('en')">EN</el-menu-item>
        </el-sub-menu>
        <el-button
          type="success"
          color="#1AD586"
          round
          class="registration"
          @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/slash`)"
          >{{ $t('Header.登记') }}</el-button
        >
      </el-menu>
    </div>
    <div class="content hidden-sm-and-up">
      <div class="mobile-menu">
        <div class="logo">
          <img @click="goRouter('/')" :src="$imgs['header/slash_logo.png']" :alt="$t('Header.s/ash，以CMHK中國移動')" />
        </div>
        <div :class="{ menuBtn: true, show: drawer }" @click="drawer = !drawer"><img :src="$imgs['header/menu_icon@2x.png']" /></div>
      </div>
      <div :class="{ menuList: true, show: drawer }">
        <el-collapse v-model="mobileCollapse" class="FirstLevelMenu">
          <el-collapse-item name="1" @click="goRouter('/')">
            <template #title>
              <div class="item">
                <span class="menuName">{{ $t('Header.首页') }}</span>
              </div>
            </template>
          </el-collapse-item>
          <!-- <el-collapse-item name="1" class="more">
            <template #title>
              <div class="item">
                <span class="menuName">{{ $t('Header.5G月费计划') }}</span>
              </div>
            </template>
            <div class="SecondaryMenu">
              <div class="item" @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/slash`)">
                <span>{{ $t('Header.5G月费计划') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
            </div>
          </el-collapse-item> -->
          <el-collapse-item name="2" class="more">
            <template #title>
              <div class="item">
                <span class="menuName">{{ $t('Header.认识s/ash') }}</span>
              </div>
            </template>
            <div class="SecondaryMenu">
              <div class="item" @click="goRouter('/slashintro')">
                <span>{{ $t('Header.咩系s/ash') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
              <div class="item" @click="goRouter('/slashintro#brand_mobile')">
                <span>{{ $t('Header.品牌介绍') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
              <div class="item" @click="goRouter('/slashintro#media_coverage_mobile')">
                <span>{{ $t('Header.媒体报导') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
              <div class="item" @click="goRouter('/slashintro#network_mobile')">
                <span>{{ $t('Header.网络实测') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="3" class="more">
            <template #title>
              <div class="item">
                <span class="menuName">{{ $t('Header.最新着数') }}</span>
              </div>
            </template>
            <div class="SecondaryMenu">
              <div class="item" v-if="isShowDashi" @click="goRouter('/ambassador_recruitment')">
                <span>{{ $t('Header.s/asher大使') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
              <div class="item" v-if="gameActivity" @click="goRouter('/Gaming_Essentials')">
                <span>{{ $t('Header.手游必備礼遇') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
              <div class="item" v-if="appStore.slash100GBState === 0" @click="goRouter('/100GB_offer')">
                <span>{{ $t('Header.免费拎100GB') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
              <!-- <div class="item" v-if="appStore.JooxState === 0" @click="goRouter('/joox_jp')">
                <span>{{ $t('Header.joox_jp') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div> -->
            </div>
          </el-collapse-item>
          <el-collapse-item name="4" class="more">
            <template #title>
              <div class="item">
                <span class="menuName">{{ $t('Header.上台Tips') }}</span>
              </div>
            </template>
            <div class="SecondaryMenu">
              <div class="item" @click="goRouter('/porting_guide')">
                <span>{{ $t('Header.转台教学') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
              <div class="item" @click="goRouter('/helpcentre')">
                <span>{{ $t('Header.帮助中心') }}</span>
                <el-icon class="arrowRight"></el-icon>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-button
          type="success"
          color="#1AD586"
          round
          class="registration"
          @click="goUrl(`${env.VITE_APP_SLASH_OPEN}/servicePlan?lang=${locale}`)"
          >{{ $t('Header.登记') }}</el-button
        >
      </div>
    </div>
  </el-header>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { useAppStore } from '../store'
import 'element-plus/theme-chalk/display.css'
import { useI18n } from 'vue-i18n'
import { usePageContext } from '../renderer/usePageContext'
import { navigate } from 'vite-plugin-ssr/client/router'
import { ArrowRight } from '@element-plus/icons-vue'
import { useApi } from '../hook'
import { resolveRoute } from 'vite-plugin-ssr/routing'

const activeIndex = ref('')
const mobileCollapse = ref([])
const drawer = ref(false)
const isClient = ref(false)
const env = import.meta.env
const showTips = ref(true)
const appStore = useAppStore()

// 移动端菜单出现的时候，禁止页面滚动
watch(
  () => drawer.value,
  () => {
    if (drawer.value) {
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
    }
  }
)

onMounted(() => {
  isClient.value = true
})

// 判断大使是否在活动中
const isShowDashi = ref(false)
watch(
  () => appStore.activityStatus,
  () => {
    const state = appStore.activityStatus.filter((item) => item.activityCode === '213971a7a46f')
    if (state[0].activityState === 0) {
      isShowDashi.value = true
    }
  }
)
// 判断游戏礼遇是否活动中
const gameActivity = ref(false)
watch(
  () => appStore.activityStatus,
  () => {
    const state = appStore.activityStatus.filter((item) => item.activityCode === '5b7f21d7f342')
    if (state[0].activityState === 0) {
      gameActivity.value = true
    }
  }
)

// 更改语言
const { locale, t } = useI18n()
const helloArray = [t('Header.公告')]

// const showTips = ref(false)
watch(
  usePageContext(),
  (context) => {
    // console.warn(context, context.urlPathname, 'test----------------')
    // if (context.urlPathname.includes('helpcentre') || context.urlPathname === '/' || context.urlPathname === `/${locale.value}/`) {
    //   showTips.value = true
    // } else {
    //   showTips.value = false
    // }
  },
  { immediate: true }
)

onMounted(async () => {
  // await useApi()
  //   .get(`${env.VITE_APP_AXIOS_URL}/bulletin/queryBulletin`)
  //   .then((res) => {
  //     console.log(res)
  //   })
  // isClient.value = true
})

const changeLang = (lang: 'tc' | 'sc' | 'en') => {
  let link = location.pathname.replace(/(\/tc\/|\/sc\/|\/en\/|\/$|\/+)/, '/' + lang + '/')
  if (location.search) {
    // 兼容活动条款详情页
    link = link + location.search
  }
  location.href = link
}

function goHome() {
  activeIndex.value = ''
  goRouter('/')
  const sub = document.getElementsByClassName('el-sub-menu')
  for (var i = 0; i < sub.length; i++) {
    if (sub[i].classList.contains('is-active')) {
      sub[i].classList.remove('is-active')
    }
  }
  const item = document.getElementsByClassName('el-menu-item')
  for (var i = 0; i < item.length; i++) {
    if (item[i].classList.contains('is-active')) {
      item[i].classList.remove('is-active')
    }
  }
}

function goRouter(name: string) {
  navigate('/' + locale.value + name)
  drawer.value = false
  mobileCollapse.value = []
}

function goUrl(url: string) {
  window.location.href = url
}
</script>
<style lang="less">
// 活动结束弹窗按钮css设置
.el-button.custom-confirm-button-class.is-round {
  padding: 0 12%;
  margin-bottom: 20px;
  background: linear-gradient(123deg, #00a7ff 0%, #00ceff 100%);
  border: none;
}
</style>
<style scoped lang="less">
@width: 1920;
.header {
  height: auto;
  width: 100vw;
  display: flex;
  padding: 0;
  justify-content: space-evenly;
  background-color: #f5fbff;
  border-bottom: solid 1px var(--el-menu-border-color);
  .content.hidden-xs-only {
    height: 104px;
    width: (1324 / @width * 100%);
    display: flex;
    justify-content: space-between;
    ::v-deep(.el-sub-menu) {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
      color: var(--el-menu-text-color);
      font-size: 14px;
    }
    ::v-deep(.menu) {
      max-width: (1324 / @width * 100%);
      align-items: center;
      height: 100%;
      .is-active {
        background: none;
        border-bottom: 2px solid;
        border-image: linear-gradient(to right, #00a7ff, #00ceff) 1;
      }
    }
    .el-menu--horizontal.el-menu {
      border: none;
    }
    .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
      background: none;
    }
    .el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
      background: none;
      color: var(--el-menu-text-color);
    }
    .el-menu--horizontal > .el-menu-item {
      padding: 0 1vw;
      border-bottom: 5px solid #fff;
    }
    .registration {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .content.hidden-sm-and-up {
    width: calc(100vw - 32px);
    .mobile-menu {
      width: 100%;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        height: 68px;
        img {
          height: 68px;
        }
      }
      .menuBtn {
        cursor: pointer;
        width: 24px;
        height: 24px;
        transform: rotate(0);
        transition: all 0.3s;
        &.show {
          transform: rotate(90deg);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .menuList {
      transform: translateY(100vh);
      position: fixed;
      top: 64px;
      left: 0px;
      right: 0px;
      bottom: 0;
      z-index: 100;
      transition: all 0.3s;
      background: rgba(255, 255, 255, 0.97);
      &.show {
        transform: translateY(0);
      }
      .FirstLevelMenu {
        padding: 0 20px;
        border: none;
        ::v-deep(.el-collapse-item__header) {
          border: none;
          height: 54px;
          background: none;
        }
        .more {
          ::v-deep(.el-collapse-item__arrow) {
            display: block;
            font-size: 20px;
            color: #00a7ff;
          }
        }
        ::v-deep(.el-collapse-item__arrow) {
          display: none;
        }

        ::v-deep(.el-collapse-item__wrap) {
          border: none;
          background: none;
        }
        ::v-deep(.el-collapse-item__content) {
          padding-bottom: 0;
        }
        .menuName {
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
        }
        .SecondaryMenu {
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;
            height: 52px;
            span {
              font-size: 12px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.8);
            }
            .arrowRight {
              font-size: 20px;
              color: #00a7ff;
            }
          }
        }
      }
      .registration {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        width: 80%;
        height: 40px;
        margin: 50px auto 0;
        display: block;
      }
    }
  }
}
.logo {
  cursor: pointer;
}
.marquee-box {
  background: #f4874e;
  width: 100%;
  .bg {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    overflow: hidden;
  }
  .icon,
  .close {
    width: 20px;
    img {
      width: 20px;
    }
  }
  .icon {
    padding-right: 10px;
  }
  .close {
    padding-left: 10px;
    cursor: pointer;
  }
  .marquee-cont {
    padding: 0 100px;
    span {
      height: 40px;
      line-height: 40px;
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      i {
        padding-right: 20px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header .content.hidden-xs-only {
    width: 96%;
    .menu {
      max-width: 96%;
      justify-content: flex-end;
    }
  }
  .marquee-box {
    width: 100%;
    .bg {
      display: flex;
      align-items: center;
      width: calc(100% - 28px);
      margin: 0 auto;
      height: 40px;
    }
  }
}
@media screen and (max-width: 768px) {
  .marquee-box {
    width: 100%;
    .bg {
      display: flex;
      align-items: center;
      width: calc(100% - 28px);
      margin: 0 auto;
      height: 40px;
    }
  }
}
.flex-grow {
  flex-grow: 1;
}
.submenu {
  display: block;
  width: 100%;
  text-decoration: none;
  color: inherit;
}
</style>
